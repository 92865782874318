import React from "react";


const Enquiry = () => {
    return (
        <>

            <div className="container">
                <h1>Enquiry</h1>
            </div>

            <div className="container mt-3">
                <div className="row">
                    <div className="col-12 bg-light py-3">
                        <h5 className="text-primary text-center my-3">Get Free Quote</h5>
                        <form action="#" method="POST">
                            <div className="form-group">
                                <label for="nameInput">Name</label>
                                <input type="text" className="form-control" id="nameInput" placeholder="Enter name" />
                            </div>
                            <div className="form-group">
                                <label for="phoneInput">Phone</label>
                                <input type="text" className="form-control" id="phoneInput" placeholder="Enter phone/mobile number" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email " />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label for="messageInput">Message</label>
                                <textarea className="form-control" id="messageInput"></textarea>
                            </div>
                            <button type="button" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Enquiry;
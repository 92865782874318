import callApi from "../utils/ApiService.js";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { SERVER_URL, IMAGE_API_URL } from "../config/constants.js";

const SubCategoryProducts = () => {
    const category = useParams();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    let getApiProducts = async (apiConfig) => {
        const callapi = await callApi(apiConfig);
        if (callapi.status == 4) {
          
        } else {
          let getResult = callapi;
          return getResult;
        }
    };  

    useEffect(()=>{
        const getProducts = async (page) => {            
            let getProductApiConfig = {
                apiUrl: `${SERVER_URL}get-products-by-subcategory/category/${category.subCategoryId}/page/${page}/pageSize/18`,
                apiRequest: {},
                apiHeaders: {},
                apiMethod: "GET",
              };
                let getProductdata = await getApiProducts(getProductApiConfig);
                if (getProductdata.status == 1) {  
                    if (getProductdata.data.data.length > 0) {
                        setProducts(getProductdata.data.data);
                  } else {
                    setProducts([]);
                  }
                } else {
                    setProducts([]);
                  }
        }
        getProducts(currentPage);
    },[currentPage,category.subCategoryId]);

    let productData = ""

    if(products.length > 0) {
      productData =  products.map((product)=>{
                  return (
<div className="col-12 col-sm-3 col-md-3 col-2 my-2">
{/* <Link to={`/product/${product.id}/${product.code}`}> */}
<div className="card shadow">
  <img className="card-img-top" src={`${IMAGE_API_URL}${product.main_image}`} alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title">{product.name.toUpperCase()}</h5>
    <p className="card-text">&#8377; {product.price}</p>
    <a href="#" className="btn btn-primary">Go somewhere</a>
  </div>
</div>
{/* </Link> */}
</div> )
                })
              }  

    return (
        <>
        { 
            <div className="container-fluid my-4">
                <div className="row">
                {productData}
                </div>
            </div>
        }
        </>
    );
}

export {SubCategoryProducts};
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
    const getCategoryData = useSelector((state) => state.category.data);

    let cateogorySlides = "";
    let subCategoriesByCategory = "";
    let productsByCategory = "";
    if (getCategoryData.length > 0) {
        cateogorySlides = getCategoryData.map((category) => {
            // return (<Slide className="mx-2" index={categoryIndex + 1} key={category.category_id}>
            //     <Link to={`/${category.category_id}/${category.category_slug}`}>
            //         <h5 className="text-dark py-2 mb-0 text-center">{category.category_name.toUpperCase()}</h5>
            //         <Image className="img-fluid mt-0 rounded shadow" alt={category.cateogory_name} src={`${IMAGE_API_URL}${category.category_image}`} />
            //     </Link>
            // </Slide>)
            return (
                  <Link className="text-white" to={`/${category.category_id}/${category.category_slug}`}>
                    {category.category_name.toUpperCase()} |&nbsp;
                  </Link>
                )
        });
        // subCategoriesByCategory = getCategoryData.map((category) => {
        //     return (
        //         <section className="my-3">
        //             <div className="container">
        //                 <h4>{category.category_name.toUpperCase()}</h4>
        //                 <CarouselProvider isPlaying="true" className="homepage-slider" naturalSlideHeight={50} naturalSlideWidth={500} totalSlides={getCategoryData.length} visibleSlides={8} step={1} isIntrinsicHeight={true}>
        //                     <Slider>
        //                         {getSubcategoryList(category.category_name, category.category_id, category.category_slug)}
        //                     </Slider>
        //                 </CarouselProvider>
        //             </div>
        //         </section>
        //     )
        // });
        // productsByCategory = getCategoryData.map((category) => {
        //     return (
        //         <section className="my-3">
        //             <div className="container">
        //                 <h4>{category.category_name.toUpperCase()}</h4>
        //                 <CarouselProvider isPlaying="true" className="homepage-slider" naturalSlideHeight={50} naturalSlideWidth={500} totalSlides={getCategoryData.length} visibleSlides={4} step={1} isIntrinsicHeight={true}>
        //                     <Slider>
        //                         {console.log(getProducts)}
        //                     </Slider>
        //                 </CarouselProvider>
        //             </div>
        //         </section>
        //     )
        // });
    };

    return (
      <>
      <div className="bg-blue py-3 footer">
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-6 col-md-6">
                    <h5 className="text-white">Contact Info</h5>
                    <h6 className="text-white">Address</h6>
                    <p className="text-white mb-3">112, Meghdoot Building :
                    94 Nehru Place, New Delhi - 110019, India</p>
                    <h6 className="text-white">Phone</h6>
                    <p className="text-white mb-3">011-26448251-52, +91-9958686757</p>
                    <h6 className="text-white">Email</h6>
                    <p className="text-white mb-3">adnetindia@gmail.com</p>
                </div>
                <div className="col-12 col-sm-6 col-md-6">
                <h5 className="text-white">Userful Links</h5>
                    <ul style={{listStyle:"none",margin:0,padding:0}}>
                        <li><Link className="text-white text-underline" to={"/"}>Home</Link></li>
                        <li><Link className="text-white text-underline" to={"/about"}>About</Link></li>
                        <li><Link className="text-white text-underline" to={"/enquiry"}>Enquiry</Link></li>
                        <li><Link className="text-white text-underline" to={"/contact"}>Contact</Link></li>
                    </ul>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12 col-sm-12">
                    <h6 className="text-white">Categories</h6>
                    {cateogorySlides}
                </div>
            </div>
        </div>
      </div>
      </>  
    );
}

export default Footer;
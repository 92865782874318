import React from "react";


const Contact = () => {
    return (
        <>

            <div className="container">
                <h1>Contact Us</h1>
                <div className="row">
                    <div className="col-12 border p-4">
                        <h5 className="text-primary">Address</h5>
                        <p><i className="fas fa-map-marker-alt text-primary"></i> 94, Meghdoot Bldg, 112, Nehru Pl Market Rd, Block G 6, Nehru Place, New Delhi, Delhi 110019</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 border p-4">
                        <h5 className="text-primary">Phone No</h5>
                        <p><i className="fas fa-phone-alt text-primary"></i> <b>Business / Dealership Enquiries :</b> 9958686757</p>
                        <p><i className="fas fa-phone-alt text-primary"></i> <b>Product Enquiry :</b> 011-26448251 / 26448252</p>
                        <p><i className="fas fa-phone-alt text-primary"></i> <b>Sales Team :</b> 8800865102, 8800856102</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 border p-4">
                        <h5 className="text-primary">Email Id</h5>
                        <p><i className="fas fa-envelope text-primary"></i> adnetindia@gmail.com</p>
                    </div>
                </div>
            </div>

            <div className="container mt-3">
                <div className="row">
                    <div className="col-6 bg-light">
                        <h5 className="text-primary text-center my-3">Get Free Quote</h5>
                        <form action="#" method="POST">
                            <div className="form-group">
                                <label for="nameInput">Name</label>
                                <input type="text" className="form-control" id="nameInput" placeholder="Enter name" />
                            </div>
                            <div className="form-group">
                                <label for="phoneInput">Phone</label>
                                <input type="text" className="form-control" id="phoneInput" placeholder="Enter phone/mobile number" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email " />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label for="messageInput">Message</label>
                                <textarea className="form-control" id="messageInput"></textarea>
                            </div>
                            <button type="button" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                    <div className="col-6">
                    <iframe loading="lazy" style={{border: 0}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.6836456089827!2d77.24920286508075!3d28.54922708245074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3c56682a07f%3A0x8c8510ca7046a1ea!2sAd%20Net%20Computronics%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1648464222648!5m2!1sen!2sin" width="600" height="750" allowfullscreen="allowfullscreen"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;
import React, {useReducer} from "react";
import { GET_CATEGORY,GET_CATEGORY_SUCCESS,GET_CATEGORY_FAILURE } from "./categoryActionTypes";

const initialState = {
    isLoading:false,
    data:{},
    error:""
}

export const categoryReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_CATEGORY:
            return {...state,isLoading:true,data:{},error:"" };
        case GET_CATEGORY_SUCCESS:
            return {...state,isLoading:false,data:action.data,error:""};
        case GET_CATEGORY_FAILURE:
            return {...state,isLoading:false,data:{},error:action.error};
        default:
            return state;
    }
}
import React from "react";
import { categoryReducer } from "../categoryContainer/categoryReducer";
import { subCategoryReducer } from "../subCategoryContainer/subCategoryReducer";
import { legacy_createStore as createStore,combineReducers,applyMiddleware,compose } from "redux";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    category:categoryReducer,
    subcategory:subCategoryReducer
})

const store = createStore(rootReducer,composeEnhancers(applyMiddleware()));

export default store;
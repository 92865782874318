import {
  GET_SUBCATEGORY,
  GET_SUBCATEGORY_SUCCESS,
  GET_SUBCATEGORY_FAILURE,
} from "./subCategoryActionTypes";

export const getSubCategory = () => {
  return {
    type: GET_SUBCATEGORY,
  };
};

export const getSubCategorySuccess = (data) => {
  return {
    type: GET_SUBCATEGORY_SUCCESS,
    data: data,
  };
};

export const getSubCategoryFailure = (error) => {
  return {
    type: GET_SUBCATEGORY_FAILURE,
    error: error,
  };
};

import {
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
} from "./categoryActionTypes";

export const getCategory = () => {
  return {
    type: GET_CATEGORY,
  };
};

export const getCategorySuccess = (data) => {
  return {
    type: GET_CATEGORY_SUCCESS,
    data: data,
  };
};

export const getCategoryFailure = (error) => {
  return {
    type: GET_CATEGORY_FAILURE,
    error: error,
  };
};

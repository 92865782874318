import Header from "./components/includes/Header";
import { Home } from "./components/Home";
import store from "./store/store";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { CategoryProducts } from "./components/CategoryProducts";
import { SubCategoryProducts } from "./components/SubCategoryProducts";
import ProductDetails from "./components/ProductDetails";
import About from "./components/About";
import Contact from "./components/Contact";
import Enquiry from "./components/Enquiry";
import Footer from "./components/includes/Footer";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/:categoryId/:category" element={<CategoryProducts />} />
          <Route path="/:categoryId/:category/:subCategoryId/:subCategory" element={<SubCategoryProducts />} />
          <Route path="/product/:productId/:productCode" element={<ProductDetails />} />
        </Routes>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState, useRef } from "react";
import { BeatLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import callApi from "../../utils/ApiService";
import {
  getCategory,
  getCategorySuccess,
  getCategoryFailure,
} from "../../categoryContainer/categoryActions";
import {
  getSubCategory,
  getSubCategorySuccess,
  getSubCategoryFailure,
} from "../../subCategoryContainer/subCategoryActions";
import { Link } from "react-router-dom";
import { SERVER_URL, IMAGE_API_URL } from "../../config/constants";

const Header = () => {
  const isLoading = useSelector((state) => state.isLoading);
  const getCategoryData = useSelector((state) => state.category.data);
  const getCategoryError = useSelector((state) => state.category.error);
  const getSubCategoryData = useSelector((state) => state.subcategory.data);
  const getSubCategoryError = useSelector((state) => state.subcategory.error);
  const dispatchCategoryAction = useDispatch();
  const [toggleMenu, setToggleMenu] = useState("");

  document.title = "Adnet  Delhi";

  const toggleMenuBar = () => {
    if(toggleMenu == "") {
      setToggleMenu("show");
    } else {
      setToggleMenu("");
    }
  }

  let categoryTable = "";

  function getSubcategoryList(category_name, category_slug, category_id) {
    let subCategoryTable = [];
    if (getSubCategoryData.length > 0) {
      for (let i = 0; i < getSubCategoryData.length; i++) {
        if (getSubCategoryData[i].category_name.toUpperCase() == category_name) {
          subCategoryTable.push(<li key={getSubCategoryData[i].sub_category_id}>
            <Link className="dropdown-item" to={`/${category_id}/${category_slug}/${getSubCategoryData[i].sub_category_id}/${getSubCategoryData[i].sub_category_slug}`}>
              {getSubCategoryData[i].sub_category_name.toUpperCase()}
            </Link>
          </li>);
        }
      }
    };
    return subCategoryTable;
  }

  if (getCategoryData.length > 0) {
    categoryTable = getCategoryData.map((category) => (
      <div className="col-md-3" key={category.category_id}>
        <h5 className="text-primary"><Link to={`/${category.category_id}/${category.category_slug}`}>{category.category_name.toUpperCase()}</Link></h5>
        <ul className="list-unstyled">
          {
            getSubcategoryList(category.category_name.toUpperCase(), category.category_slug, category.category_id)
          }
        </ul>
      </div>
    ));
  }

  let getApiCategories = async (apiConfig) => {
    const callapi = await callApi(apiConfig);
    if (callapi.status == 4) {
      // console.log("/logout");
    } else {
      let getResult = callapi;
      return getResult;
    }
  };

  useEffect(() => {
    dispatchCategoryAction(getCategory());
    let getCategoriesApiConfig = {
      apiUrl: `${SERVER_URL}get-categories`,
      apiRequest: {},
      apiHeaders: {},
      apiMethod: "GET",
    };
    let getCategories = async () => {
      let getcategorydata = await getApiCategories(getCategoriesApiConfig);
      if (getcategorydata.status == 1) {
        if (getcategorydata.data.data.length > 0) {
          dispatchCategoryAction(getCategorySuccess(getcategorydata.data.data));
        }
      } else {
        dispatchCategoryAction(getCategoryFailure(getcategorydata));
      }
    };
    getCategories();
  }, []);

  useEffect(() => {
    dispatchCategoryAction(getSubCategory());
    let getSubCategoriesApiConfig = {
      apiUrl: `${SERVER_URL}get-sub-categories`,
      apiRequest: {},
      apiHeaders: {},
      apiMethod: "GET",
    };
    let getSubCategories = async () => {
      let getsubcategorydata = await getApiCategories(
        getSubCategoriesApiConfig
      );
      if (getsubcategorydata.status == 1) {
        if (getsubcategorydata.data.data.length > 0) {
          dispatchCategoryAction(
            getSubCategorySuccess(getsubcategorydata.data.data)
          );
        }
      } else {
        dispatchCategoryAction(getSubCategoryFailure(getsubcategorydata));
      }
    };
    getSubCategories();
  }, []);

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">
            <img src="/LOGO.png" className="img-fluid" style={{ width: "150px" }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={()=>toggleMenuBar()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${toggleMenu}`} id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>

              <li className="nav-item dropdown megamenu d-none d-md-block">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                >
                  Services
                </a>
                <div className="dropdown-menu" id="megamenu">
                  {isLoading ? (
                    <BeatLoader loading />
                  ) : (
                    <div className="row">
                      {(categoryTable) ? categoryTable : <BeatLoader />}
                    </div>
                  )}
                </div>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/enquiry">
                  Enquiry
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

import React from "react";


const About = () => {
    return (
        <>
        
        <div className="container">
            <h1 className="text-primary">About Us</h1>
            <div className="row">
                <div className="col-12">
                <p>AD NET Computronics India Private Limited was established by a highly ambitious and dedicated entrepreneur Arvind Yadav in 1998 in New Delhi at Nehru Place. By 2006, showing a robust growth, AD NET expanded in India with more than 1000+ dealers PAN India.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-12">
                <p>AD NET offers a huge range of over 1200 High Quality Computer  Desktop, Laptop, Mobility, Networking, CCTV Accessories & IOT Products  at  reasonable prices. All AD NET products are well known in the market and the demand is constantly rising due to excellent and unmatched quality offered. Apart from excellent quality of the products AD NET ensures reliable service, timely delivery and reasonable prices.</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default About;
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import callApi from "../utils/ApiService";
import { SERVER_URL, IMAGE_API_URL } from "../config/constants";

const ProductDetails = () => {
    const { productId, productCode } = useParams();
    const [productDetails, setProductDetails] = useState({});
    const [productImages, setProductImages] = useState({});


    useEffect(() => {
        let productDetailsApiConfig = {
            apiUrl: `${SERVER_URL}get-product-details/id/${productId}/code/${productCode}`,
            apiRequest: {},
            apiHeaders: {},
            apiMethod: "GET",
        }
        const getProductDetails = async () => {
            let productDetailsData = await callApi(productDetailsApiConfig);
            if (productDetailsData.status == 1) {

                if (productDetailsData.data.status > 0) {
                    let productData = productDetailsData.data.data;
                    setProductDetails(productData);
                    setProductImages(productData.productImages);
                } else {
                    setProductDetails({});
                }
            } else {
                setProductDetails({});
            }
        }
        getProductDetails();
    }, [productCode]);

    let productImageTag = "";
    if (productImages.length > 0) {
        productImageTag = (productImages.map((image) => {
            return (<img src={`${IMAGE_API_URL}${image.image_path}`} alt={productDetails.productName} className="img-fluid" />)
        }))
    }

    return (
        <>
            <div className="container-fluid mt-5 product_details">
                <div className="row">

                    <div className="col-md-5 text-center">
                        <img src={`${IMAGE_API_URL}${productDetails.productMainImage}`} alt={productDetails.productName} className="product-image" />
                    </div>


                    <div className="col-md-7">
                        <h1 className="product-title">{productDetails.productName}</h1>
                        <p className="product-price">&#8377; {productDetails.price}</p>
                        {/* <button className="btn btn-custom btn-lg mb-3">Add to Cart</button> */}


                        <div className="tabs-section">
                            <ul className="nav nav-tabs" id="productTabs" role="tablist">
                                {/* <li className="nav-item">
            <a className="nav-link" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">Overview</a>
          </li> */}
                                <li className="nav-item active">
                                    <a className="nav-link" id="description-tab" data-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="false">Description</a>
                                </li>
                                {/* <li className="nav-item">
            <a className="nav-link" id="specifications-tab" data-toggle="tab" href="#specifications" role="tab" aria-controls="specifications" aria-selected="false">Specifications</a>
          </li> */}
                            </ul>
                            <div className="tab-content" id="productTabsContent">

                                {/* <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
            <p className="product-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac ipsum eu risus varius tempus. Aliquam euismod est a ex pretium, non euismod dui placerat.</p>
            <ul className="feature-list">
              <li>Feature 1: Lorem ipsum dolor sit amet</li>
              <li>Feature 2: Aliquam euismod est a ex pretium</li>
              <li>Feature 3: Proin ac ipsum eu risus varius tempus</li>
              <li>Feature 4: Aliquam euismod est a ex pretium</li>
            </ul>
          </div> */}

                                <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                    <p className="product-description">{productDetails.description}</p>
                                </div>

                                {/* <div className="tab-pane fade" id="specifications" role="tabpanel" aria-labelledby="specifications-tab">
            <ul className="specifications">
              <li><strong>Brand:</strong> Example Brand</li>
              <li><strong>Model Number:</strong> EX1234</li>
              <li><strong>Weight:</strong> 1.5kg</li>
              <li><strong>Dimensions:</strong> 30 x 20 x 10 cm</li>
            </ul>
          </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {productImageTag}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetails;
import axios from "axios";


const callApi = async (apiConfig) => {
    let apiStatus = 0;
    let result = {};
    let apiResponse;
    let response;
    let {apiUrl, apiRequest, apiHeaders, apiMethod} = apiConfig;
    if(apiMethod == "GET") {
        apiResponse = await axios.get(apiUrl)
        .then((resp)=>{
            apiStatus = 1;
            response = Object.assign(result,{status:apiStatus,data:resp.data});
            return response;
        })
        .catch((err)=>{
            response = Object.assign(result,{status:apiStatus,data:err.response.data});
            return response;
        });
    } else if (apiMethod == "PUT") {
        apiResponse = await axios.put(apiUrl,apiRequest,{headers:apiHeaders})
        .then((resp)=>{
            apiStatus = 1;
            response = Object.assign(result,{status:apiStatus,data:resp.data});
            return response;
        })
        .catch((err)=>{
            response = Object.assign(result,{status:apiStatus,data:err.response.data});
            return response;
        });
    } else {
        apiResponse = await axios.post(apiUrl,apiRequest,{headers:apiHeaders})
        .then((resp)=>{
            apiStatus = 1;
            response = Object.assign(result,{status:apiStatus,data:resp.data});
            return response;
        })
        .catch((err)=>{
            response = Object.assign(result,{status:apiStatus,data:err.response.data});
            return response;
        });
    }
    return apiResponse;
}

export default callApi;